
import { defineComponent, PropType, watch } from 'vue';
import Routes from '@/router/Routes';
import store from '@/store';
import Markdown from 'vue3-markdown-it';
import { useRoute, useRouter } from 'vue-router';
import { Topic } from '../../models';

export default defineComponent({
  components: {
    Markdown
  },
  props: {
    chapter: {
      type: Object as PropType<Event>,
      required: true
    }
  },
  emits: ['prerequisitClick'],
  setup() {
    const router = useRouter();
    const route = useRoute();

    watch(() => route.params.chapterName, fetchEvent);

    async function fetchEvent() {
      await store.dispatch('syllabusModule/fetchChapter', {
        name: route.params.chapterName,
        syllabus: store.getters['syllabusModule/syllabus']
      });
    }

    function handleTopicClick(topic: Topic) {
      router.push({
        name: Routes.SYLLABUS_TOPIC_DETAILS,
        params: {
          topicName: topic.title || '',
          chapterName: route.params.chapterName
        }
      });
    }

    return {
      handleTopicClick,
      route
    };
  }
});
